import { defineComponent } from '~/scripts/utils/alpine'

/**
 * Tabs options
 * @property {number} [startIndex=0] - Start tab index
 * @property {string} [startId=false] - Start tab id (takes precedence over startIndex)
 * @property {boolean} [useHash=false] - Bind the tabs instance to the hash in the URL
 * @property {boolean} [autoScroll=true] - Automatically scroll to the selected tab
 */
export interface TabsOptions {
  startIndex?: number
  startId?: string
  useHash?: boolean
  autoScroll?: boolean
}

/**
 * Tabs component
 * @example Minimal:
    <div x-data="Tabs" x-bind="tabs">
      <div x-bind="tabList">
        <button x-bind="tab" class="aria-selected:underline">Tab 1</button>
        <button x-bind="tab" class="aria-selected:underline">Tab 2</button>
        <button x-bind="tab" class="aria-selected:underline">Tab 3</button>
        <button x-bind="tab" class="aria-selected:underline">Tab 4</button>
      </div>
      <section x-bind="tabPanel">Panel 1</section>
      <section x-cloak x-bind="tabPanel">Panel 2</section>
      <section x-cloak x-bind="tabPanel">Panel 3</section>
      <section x-cloak x-bind="tabPanel">Panel 4</section>
    </div>
  *
  * @example With options:
    <div x-data="Tabs({startIndex: 1, useHash: true})" x-bind="tabs">
 */
export default defineComponent((options?: TabsOptions) => ({
  currentIndex: options?.startIndex ?? 0,
  autoScroll: options?.autoScroll ?? true,
  async init() {
    this.$watch('currentIndex', (newIndex) => {
      if (options?.useHash) {
        // Update hash
        const id = this.$id('tab', newIndex + 1)
        const tabPanel = this.$root.querySelector(
          `[role="tabpanel"][aria-labelledby="${id}"]`,
        )
        history.pushState({}, '', '#' + (tabPanel?.id ?? ''))
      }
    })

    // Select tab by startId
    if (options?.startId) {
      await this.$nextTick()
      this.selectById(options.startId)
    }

    if (options?.useHash) {
      // Select tab on hash change
      globalThis.addEventListener('hashchange', () => {
        this.selectById(globalThis.location.hash.replace('#', ''))
      })
    }
  },
  select(index: number) {
    this.currentIndex = index
  },
  selectById(id: string) {
    if (!id) return
    const panel = this.$root.querySelector<HTMLElement>('#' + id)
    if (panel) {
      this.select(this.getIndex(panel))
    }
  },
  isSelected(index: number) {
    return this.currentIndex === index
  },
  getIndex(element: HTMLElement) {
    const type = element.getAttribute('x-bind')
    return [...this.$root.querySelectorAll(`[x-bind="${type}"]`)].indexOf(
      element,
    )
  },
  tabs: {
    'x-id': () => ['tab', 'panel'],
  },
  tabList: {
    'x-ref': 'tabList',
    ':role': () => 'tablist',
    '@keydown.right.prevent.stop'() {
      this.$focus.wrap().next()
    },
    '@keydown.home.prevent.stop'() {
      this.$focus.first()
    },
    '@keydown.page-up.prevent.stop'() {
      this.$focus.first()
    },
    '@keydown.left.prevent.stop'() {
      this.$focus.wrap().prev()
    },
    '@keydown.end.prevent.stop'() {
      this.$focus.last()
    },
    '@keydown.page-down.prevent.stop'() {
      this.$focus.last()
    },
  },
  tab: {
    ':id'() {
      return this.$id('tab', this.getIndex(this.$el).toString())
    },
    ':role': () => 'tab',
    ':type': () => 'button',
    ':tabindex'() {
      return this.getIndex(this.$el) === this.currentIndex ? 0 : -1
    },
    ':aria-selected'() {
      return this.getIndex(this.$el) === this.currentIndex
    },
    ':aria-controls'() {
      return this.$id('panel', this.getIndex(this.$el).toString())
    },
    '@click'() {
      if (this.autoScroll) {
        this.$el.scrollIntoView({
          inline: 'start',
          behavior: 'smooth',
          block: 'nearest',
        })
      }
      this.selectById(this.$el.getAttribute('aria-controls') ?? '')
    },
    '@focus'() {
      this.selectById(this.$el.getAttribute('aria-controls') ?? '')
    },
  },
  tabPanel: {
    ':id'() {
      return this.$id('panel', this.getIndex(this.$el).toString())
    },
    ':tabindex'() {
      return 0
    },
    ':role': () => 'tabpanel',
    ':aria-labelledby'() {
      return this.$id('tab', this.getIndex(this.$el).toString())
    },
    async 'x-init'() {
      // Wait for dynamic id to be set (:id="")
      await this.$nextTick()

      // Select tab on init if current hash matches
      if (
        this.currentIndex === 0 &&
        this.$el.id &&
        this.$el.id === globalThis.location.hash.replace('#', '')
      ) {
        this.select(this.getIndex(this.$el))
      }
    },
    'x-show'() {
      return this.isSelected(this.getIndex(this.$el))
    },
  },
}))
