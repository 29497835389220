import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  invalidFields: [] as string[],
  get canSubmit() {
    return this.invalidFields.length === 0
  },
  init() {
    // Disable submit button if there are invalid fields
    this.$watch('canSubmit', (value) => {
      const button = this.$root.querySelector<HTMLButtonElement>(
        'button[type="submit"]',
      )
      if (!button) return
      button.disabled = !value
    })
  },
  root: {
    '@input-tel:input'(
      event: CustomEvent<{ element: HTMLElement; valid: boolean }>,
    ) {
      // Remove error message if input is valid
      if (event.detail.valid) {
        const { id } = event.detail.element
        this.invalidFields = this.invalidFields.filter((field) => field !== id)
        this.removeInputTelErrorMessage(event.detail.element)
      }
    },
    '@input-tel:blur'(
      event: CustomEvent<{ element: HTMLElement; valid: boolean }>,
    ) {
      const { id } = event.detail.element

      // Remove error message if input is valid
      if (event.detail.valid) {
        this.invalidFields = this.invalidFields.filter((field) => field !== id)
        this.removeInputTelErrorMessage(event.detail.element)
      }
      // Add error message if input is invalid
      else if (!this.invalidFields.includes(id)) {
        this.invalidFields.push(id)
        this.addInputTelErrorMessage(event.detail.element)
      }
    },
  },
  addInputTelErrorMessage(element: HTMLElement) {
    const field = element.closest<HTMLDivElement>('.gfield')
    if (!field) return
    field.classList.add('gfield_error')
    const message = element.dataset.errorMessage || 'Invalid'
    field.insertAdjacentHTML(
      'beforeend',
      `<div class="gfield_description validation_message gfield_validation_message">${message}</div>`,
    )
  },
  removeInputTelErrorMessage(element: HTMLElement) {
    const field = element.closest<HTMLDivElement>('.gfield')
    if (!field) return
    field.classList.remove('gfield_error')
    field.querySelector('.gfield_description')?.remove()
  },
}))
