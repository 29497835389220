import '~/styles/main.css'
import 'vidstack/player'
import 'vidstack/player/layouts'
import 'vidstack/player/ui'
import App from '~/scripts/App'
import setScrollbarWidth from '~/scripts/tailwindcss/plugins/spacing-container/set-scrollbar-width'
import { getSource } from '~/scripts/utils/tracking'
import { flat } from 'radashi'

// Create a better Eulerian Analytics push function
globalThis.eaPush = (event) => {
  if (globalThis.EA_push) {
    globalThis.EA_push(...flat(Object.entries(event)))
  }
}

// Store source in session storage
const source = getSource()
if (source) {
  globalThis.sessionStorage.setItem('source', source)
}
// If source is not defined yet, set it to 'naturel'
else if (!globalThis.sessionStorage.getItem('source')) {
  globalThis.sessionStorage.setItem('source', 'naturel')
}

// Extend StudizzBot API to send gtm events
let studizzBotExtended = false
function extendStudizzBot() {
  if (studizzBotExtended) return

  if (globalThis.StudizzBotClose) {
    const { StudizzBotClose: close } = globalThis
    globalThis.StudizzBotClose = () => {
      close()
      globalThis.dataLayer?.push({ event: 'navigation_fermeture-chatbot' })
    }
    studizzBotExtended = true
  } else {
    setTimeout(extendStudizzBot, 1000)
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  await document.fonts.ready
  App()

  extendStudizzBot()
})

const scrollbarWidthEvents = ['load', 'resize']
for (const event of scrollbarWidthEvents) {
  globalThis.addEventListener(event, () => {
    setScrollbarWidth()
  })
}
setScrollbarWidth()
