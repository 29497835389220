import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  init() {
    this.$root.addEventListener('submit', this.onSubmit)
  },
  onSubmit(event: SubmitEvent) {
    if (!globalThis.dataLayer) return

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const data: Record<string, string> = {}

    // Map our keys to the dataLayer keys
    const map = new Map([
      ['admission', 'education_level'],
      ['program', 'program_selected'],
      ['rythm', 'program_format'],
    ])

    for (const key of formData.keys()) {
      if (key === '_gl') continue // Ignore _gl key

      const element = form.elements.namedItem(key)

      // Handle input element
      if (element instanceof HTMLInputElement) {
        data[map.get(key) ?? key] = element.value || 'null'
      }
      // Handle select element
      else if (element instanceof HTMLSelectElement) {
        const value = []
        for (const option of element.selectedOptions) {
          if (option.value !== '') {
            value.push(option.label.toLowerCase())
          }
        }
        data[map.get(key) ?? key] = value.join(', ') || 'null'
      }
    }

    globalThis.dataLayer.push({
      event: 'navigation_recherche-moteur',
      major_selected: 'null',
      program_language: 'null',
      school_campus: 'null',
      ...data,
    })
  },
}))
