import { debounce } from 'radashi'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  intersectionObserver: undefined as IntersectionObserver | undefined,
  init() {
    window.addEventListener(
      'resize',
      debounce({ delay: 100 }, () => {
        this.cleanup()
        this.setup()
      }),
    )

    this.setup()
  },
  setup() {
    const items = this.$root.querySelectorAll('.item')

    const rect = this.$refs.trigger.getBoundingClientRect()
    const left = rect.left * -1
    const right = window.innerWidth * -1 + rect.right
    const rootMargin = `0px ${right}px 0px ${left}px`

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add('active')
          } else {
            entry.target.classList.remove('active')
          }
        }
      },
      {
        root: this.$root,
        rootMargin,
        threshold: 0.01,
      },
    )

    this.intersectionObserver = intersectionObserver

    for (const item of items) {
      intersectionObserver.observe(item)
    }
  },
  cleanup() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
      this.intersectionObserver = undefined
    }
  },
}))
