import Alpine from 'alpinejs'
import type Lenis from 'lenis'
import { useElementSize } from '~/scripts/composables/useElementSize'
import setScrollbarWidth from '~/scripts/tailwindcss/plugins/spacing-container/set-scrollbar-width'
import { setRootCSSVariable } from '~/scripts/utils/css'

const ui = {
  hasHero: false,

  init() {
    if (
      document.querySelector(
        '.b-hero,.b-hero-big,.b-hero-home,.b-hero-training',
      )
    ) {
      this.hasHero = true
    }

    if (globalThis.lenis) {
      globalThis.lenis.on('scroll', (lenis: Lenis) => {
        this.scrollY = lenis.scroll
      })
    } else {
      console.warn(
        "Lenis is not instantiated so everything that uses $store.ui.scrollY won't work as expected.",
      )
    }

    // Effect of scrollLockedBy
    Alpine.effect(() => {
      const locked = this.scrollLockedBy.length > 0
      const html = document.documentElement
      html.classList[locked ? 'add' : 'remove']('lock-scroll')

      if (globalThis.lenis) {
        globalThis.lenis[locked ? 'stop' : 'start']()
      }
    })

    // Effect of headerHeight and wpAdminBarHeight
    Alpine.effect(() => {
      this.offsetTop =
        this.headerHeight + (this.wpAdminBarFixed ? this.wpAdminBarHeight : 0)
      setRootCSSVariable('offset-top', `${this.offsetTop}px`)
      this.scrollPaddingTop = this.offsetTop
      setRootCSSVariable('scroll-padding-top', `${this.scrollPaddingTop}px`)
    })

    // Observe WP Toolbar height
    const wpAdminBar = document.querySelector<HTMLElement>('#wpadminbar')
    if (wpAdminBar) {
      useElementSize(wpAdminBar, (size) => {
        this.setWpToolbarHeight(size.height)
      })
    }

    // Observe WP Toolbar fixed
    const wpAdminBarFixedMediaQuery =
      globalThis.matchMedia('(min-width: 601px)')
    this.setWpToolbarFixed(wpAdminBarFixedMediaQuery.matches)
    wpAdminBarFixedMediaQuery.addEventListener('change', (event) => {
      this.setWpToolbarFixed(event.matches)
    })
  },

  // Scroll
  scrollY: 0,

  // Lock scroll
  scrollLockedBy: [] as string[],
  lockScrollBy(key: string) {
    this.scrollLockedBy.push(key)
    setTimeout(() => {
      setScrollbarWidth()
    }, 100)
  },
  unlockScrollBy(key: string) {
    this.scrollLockedBy = this.scrollLockedBy.filter((item) => item !== key)
    setTimeout(() => {
      setScrollbarWidth()
    }, 100)
  },

  // Header
  topbarHeight: 0,
  navbarHeight: 0,
  menuOffsetTop: 0,
  get headerHeight() {
    return this.topbarHeight + this.navbarHeight
  },
  setTopbarHeight(value: number) {
    this.topbarHeight = value
    setRootCSSVariable('topbar-height', `${value}px`)
  },
  setNavbarHeight(value: number) {
    this.navbarHeight = value
    setRootCSSVariable('navbar-height', `${value}px`)
  },
  setMenuOffsetTop(value: number) {
    this.menuOffsetTop = value
    setRootCSSVariable('menu-offset-top', `${value}px`)
  },

  // WP Toolbar
  wpAdminBarFixed: false,
  wpAdminBarHeight: 0,
  setWpToolbarHeight(value: number) {
    this.wpAdminBarHeight = value
    setRootCSSVariable('wp-admin-bar-height', `${value}px`)
  },
  setWpToolbarFixed(value: boolean) {
    this.wpAdminBarFixed = value
    setRootCSSVariable('wp-admin-bar-fixed', value ? '1' : '0')
  },

  // Offset
  offsetTop: 0,
  scrollPaddingTop: 0,
}

// Declare uiStore as an Alpine store module
declare module 'alpinejs' {
  interface Stores {
    ui: typeof ui
  }
}

export default ui
