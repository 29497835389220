import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  overlay: false,
  visible: false,
  init() {
    this.$watch('$store.ui.scrollY', (scrollY: number) => {
      this.overlay = scrollY >= 10
    })
  },
  toggleVisible() {
    this.visible = !this.visible
  },
}))
