import { random } from 'radashi'
import { defineComponent } from '~/scripts/utils/alpine'

export interface HeroHomeOptions {
  backgroundImagesCount: number
}

export default defineComponent((options?: HeroHomeOptions) => {
  return {
    backgroundImage: options?.backgroundImagesCount
      ? random(0, options.backgroundImagesCount - 1)
      : undefined,
  }
})
