import { defineComponent } from '~/scripts/utils/alpine'
import VanishTween from '~/scripts/embla/plugins/vanish-tween'
import screens from '#tailwindcss/screens.json'

export default defineComponent(() => ({
  vanishTween: VanishTween({
    active: false,
    breakpoints: {
      [`(min-width: ${screens.lg})`]: {
        active: true,
      },
    },
  }),
}))
