import type {
  EmblaCarouselType,
  EmblaEventType,
  CreatePluginType,
  CreateOptionsType,
} from 'embla-carousel'
import { Sine } from 'gsap'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type Options = CreateOptionsType<{}>

export type JobOffersTween = CreatePluginType<
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  {},
  Options
>

export type JobOffersTweenOptions = JobOffersTween['options']

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max)
}

function JobOffersTween(options: JobOffersTweenOptions = {}): JobOffersTween {
  let carousel: EmblaCarouselType

  let tweenTargets: HTMLElement[] = []

  function setTweenTargets(): void {
    tweenTargets = carousel
      .slideNodes()
      .map((slideNode) => slideNode.children[0] as HTMLElement)
  }

  function update(
    carousel: EmblaCarouselType,
    eventName: EmblaEventType,
  ): void {
    const engine = carousel.internalEngine()
    const scrollProgress = carousel.scrollProgress()
    const slidesInView = carousel.slidesInView()
    const isScrollEvent = eventName === 'scroll'

    for (const [snapIndex, scrollSnap] of carousel.scrollSnapList().entries()) {
      const diffToTarget =
        (scrollSnap - scrollProgress) * carousel.scrollSnapList().length

      const slidesInSnap = engine.slideRegistry[snapIndex]

      for (const slideIndex of slidesInSnap) {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) continue

        const target = tweenTargets[slideIndex]
        const value = clamp(Math.abs(diffToTarget), 0, 1)
        const easedValue = Sine.easeInOut(value)
        target.style.transform = `translateX(${easedValue * -3}rem)`
        target.style.opacity = `${1 - easedValue / 4}`
      }
    }
  }

  function init(instance: EmblaCarouselType): void {
    carousel = instance

    setTweenTargets()
    update(carousel, 'init')

    carousel
      .on('reInit', setTweenTargets)
      .on('reInit', update)
      .on('scroll', update)
      .on('slideFocus', update)
  }

  function destroy(): void {
    carousel!
      .off('reInit', setTweenTargets)
      .off('reInit', update)
      .off('scroll', update)
      .off('slideFocus', update)

    for (const target of tweenTargets) {
      target.removeAttribute('style')
    }
  }

  return {
    name: 'jobOffersTween',
    options,
    init,
    destroy,
  }
}

export default JobOffersTween
