import { useElementSize } from '~/scripts/composables/useElementSize'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { shuffleChildren } from '~/scripts/utils/dom'

export interface MarqueeOptions {
  scrollAmount?: number
  shuffle?: boolean
}

/**
 * Marquee
 * @param {Object} options
 * @param {number} [options.scrollAmount=180] - The amount of pixels to scroll per second
 * @param {boolean} [options.shuffle=false] - Whether to shuffle the items
 */
export default defineComponent((options?: MarqueeOptions) => ({
  isPlaying: false,
  duration: 5, // seconds
  init() {
    const { item } = this.$refs
    if (!item) return

    const { scrollAmount, shuffle } = {
      scrollAmount: 180,
      shuffle: false,
      ...options,
    }

    const scrollTrigger = ScrollTrigger.create({
      trigger: this.$root,
      start: 'top bottom',
      end: 'bottom top',
      onToggle: ({ isActive }) => {
        this.isPlaying = isActive
      },
    })

    if (shuffle) {
      const children = shuffleChildren(item)

      if (!item.parentElement) return

      for (const group of item.parentElement.children) {
        group.replaceChildren(children.cloneNode(true))
      }
    }

    useElementSize(item, ({ width }) => {
      this.duration = width / scrollAmount
      scrollTrigger.refresh()
    })
  },
}))
