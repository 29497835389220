import Alpine from 'alpinejs'

/**
 * Reactive size of an HTML element.
 */
export function useElementSize(
  element: HTMLElement,
  callback?: (size: { width: number; height: number }) => void,
) {
  const rect = element.getBoundingClientRect()
  let observer: ResizeObserver | undefined
  const cleanup = () => {
    if (observer) {
      observer.disconnect()
      observer = undefined
    }
  }

  const size = Alpine.reactive({
    width: rect.width,
    height: rect.height,
    destroy() {
      cleanup()
    },
  })

  if (callback) {
    Alpine.effect(() => {
      callback({ width: size.width, height: size.height })
    })
  }

  observer = new ResizeObserver((entries) => {
    const { blockSize, inlineSize } = entries[0].borderBoxSize[0]
    size.width = inlineSize
    size.height = blockSize
  })

  observer.observe(element)

  return size
}
