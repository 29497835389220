import { useElementSize } from '~/scripts/composables/useElementSize'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '#tailwindcss/screens.json'

export default defineComponent(() => ({
  activeMenu: undefined as string | undefined,
  mobileMenuVisible: false,
  mobileActiveMenu: undefined as string | undefined,
  init() {
    const { topbar, navbar } = this.$refs

    if (topbar) {
      useElementSize(topbar, ({ height }) => {
        this.$store.ui.setTopbarHeight(height)
      })
    }

    if (navbar) {
      useElementSize(navbar, ({ height }) => {
        this.$store.ui.setNavbarHeight(height)
      })
    }

    this.$watch('mobileMenuVisible', (value) => {
      if (value) {
        this.$store.ui.lockScrollBy('mobile-menu')
        this.updateMenuOffsetTop(navbar)
      } else {
        this.$store.ui.unlockScrollBy('mobile-menu')
        this.mobileActiveMenu = undefined
      }
    })

    this.$watch('activeMenu', (value, oldValue) => {
      if (oldValue) {
        this.$store.ui.unlockScrollBy('menu-' + oldValue)
      }

      if (value) {
        this.updateMenuOffsetTop(navbar)
        this.$store.ui.lockScrollBy('menu-' + value)
      }
    })

    // Reset menus (resize might a switch from mobile to desktop or vice versa)
    const mediaQuery = globalThis.matchMedia(`(min-width: ${screens.lg})`)
    mediaQuery.addEventListener('change', () => {
      this.activeMenu = undefined
      this.mobileMenuVisible = false
      this.mobileActiveMenu = undefined
    })
  },
  updateMenuOffsetTop(navbar: HTMLElement) {
    const { bottom } = navbar.getBoundingClientRect()
    this.$store.ui.setMenuOffsetTop(bottom)
  },
  toggleMenu(menuId: string) {
    this.activeMenu = this.activeMenu === menuId ? undefined : menuId
  },
  toggleMobileMenuVisible() {
    this.mobileMenuVisible = !this.mobileMenuVisible
  },
}))
