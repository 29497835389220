import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import { gsap, Expo } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useDataScroll } from 'data-scroll'

import { useLenis } from '~/scripts/composables/useLenis'

import registerStoreModules from '~/scripts/store'
import registerDirectives from '~/scripts/directives'
import registerComponents from '~/scripts/components'
import registerMagics from '~/scripts/magics'
import { scrollToAnchor } from '~/scripts/utils/scroll'
import screens from '#tailwindcss/screens.json'

export default function App() {
  gsap.registerPlugin(ScrollTrigger)

  useLenis({
    duration: 1.2,
    prevent: (element) => {
      if (element.id === '#wpadminbar') return true
      if (element.id === '#query-monitor-main') return true
      if (element.classList.contains('cky-modal')) return true
      if (element.role === 'dialog') return true
      if (element.tagName === 'DIALOG') return true
      return false
    },
    easing: Expo.easeOut,
    useTicker(lenis) {
      lenis.on('scroll', ScrollTrigger.update)

      gsap.ticker.add((time) => {
        lenis.raf(time * 1000)
      })

      gsap.ticker.lagSmoothing(0)
    },
  })

  globalThis.Alpine = Alpine

  // Register plugins
  Alpine.plugin(collapse)
  Alpine.plugin(focus)
  Alpine.plugin(intersect)

  registerMagics()
  registerStoreModules()
  registerDirectives()
  registerComponents()

  document.addEventListener('alpine:initialized', () => {
    // Make Alpine's store available globally
    const result = document.querySelector('[x-data]')

    if (result) {
      // @ts-expect-error Alpine's store will be available at this path so this is type-safe
      globalThis.$store = result._x_dataStack[0].$store
    } else {
      throw new Error(
        'No x-data found, the page should have at least one element with the x-data attribute.',
      )
    }

    useDataScroll({ screens })
  })

  Alpine.start()

  // DataLayer events
  if (globalThis.dataLayer) {
    for (const element of document.querySelectorAll<HTMLElement>(
      '[data-event]',
    )) {
      element.addEventListener('click', () => {
        const { event } = element.dataset
        if (event) {
          globalThis.dataLayer?.push({ event })
        }
      })
    }
  }

  // Smooth scroll to anchors
  for (const anchor of document.querySelectorAll<HTMLAnchorElement>(
    'main a[href*="#"]:not([href$="#"])',
  )) {
    anchor.addEventListener('click', (event) => {
      scrollToAnchor(anchor, event, { blacklist: [] })
    })
  }
}
