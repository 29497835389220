export function getSource(): string {
  const params = new URLSearchParams(globalThis.location.search)

  if (params.get('gclid')) {
    return 'google-ads'
  }

  const utmSource = params.get('utm_source')
  if (utmSource) {
    if (
      [
        'bing',
        'facebook',
        'google',
        'instagram',
        'linkedin',
        'snapchat',
        'tiktok',
        'twitter',
        'youtube',
      ].includes(utmSource)
    ) {
      const utmMedium = params.get('utm_medium') === 'cpc' ? 'ads' : 'organic'
      return `${utmSource}-${utmMedium}`
    } else {
      return sourceMap.get(utmSource) || utmSource
    }
  }

  if (params.get('fbclid')) {
    return 'facebook-ads'
  }

  // Only one of these parameters cam be present
  const eulerian =
    params.get('ead-publisher') ||
    params.get('esc-publisher') ||
    params.get('esl-k') ||
    params.get('eml-publisher') ||
    params.get('etf-publisher')

  if (eulerian) {
    const map = new Map([
      ...eulerianSourceMap.entries(),

      // Generic
      ...sourceMap.entries(),
    ])
    const params = eulerian.split('|')

    for (const parameter of params) {
      if (map.has(parameter)) return map.get(parameter)!
    }

    if (!eulerian.includes('|')) return eulerian
  }

  return ''
}

const eulerianSourceMap = new Map([
  ['Bing', 'bing-ads'],
  ['fb', 'facebook-ads'],
  ['Facebook', 'facebook-organic'],
  ['google-ads', 'google-ads'],
  ['Google', 'google-organic'],
  ['ig', 'instagram-ads'],
  ['Instagram', 'instagram-organic'],
  ['linkedin', 'linkedin-ads'],
  ['Linkedin', 'linkedin-organic'],
  ['snapchat', 'snapchat-ads'],
  ['Snapchat', 'snapchat-organic'],
  ['tiktok', 'tiktok-ads'],
  ['Tiktok', 'tiktok-organic'],
  ['twitter', 'twitter-ads'],
  ['Twitter', 'twitter-organic'],
  ['Youtube', 'youtube'],
])

const sourceMap = new Map([
  ['an', 'audience-network'],
  ['referer', 'referants'],
  ['jellyfish', 'tradelab'],
  ['Indeed', 'indeed'],
  ['Hellowork', 'hellowork'],
  ['Diplomeo', 'diplomeo'],
  ['EduOpinion', 'eduopinion'],
  ['Antvoice', 'antvoice'],
  ['Taboola', 'taboola'],
  ['Outbrain', 'outbrain'],
  ['Loyal', 'loyal'],
  ['Ogury', 'ogury'],
  ['Beop', 'beop'],
  ['StudyLink', 'studylink'],
  ['Nomad', 'nomad'],
  ['StudyPortal', 'studyportal'],
  ['Shiksha', 'shiksha'],
  ['Thotis', 'othis'],
  ['Mozoo', 'mozoo'],
  ['Azerion', 'azerion'],
  ['Keystone', 'keystone'],
  ['abtasty', 'abtasty'],
  ['acces-direct', 'acces-direct'],
  ['adroll', 'adroll'],
  ['criteo', 'criteo'],
  ['digischool', 'digischool'],
  ['dimension-bachelor', 'dimension-bachelor'],
  ['dimension-commerce', 'dimension-commerce'],
  ['dimension-ingenieur', 'dimension-ingenieur'],
  ['diplomeo', 'diplomeo'],
  ['eduniversal', 'eduniversal'],
  ['etudiant-figaro', 'etudiant-figaro'],
  ['event-digital-groupe', 'event-digital-groupe'],
  ['figaro', 'figaro'],
  ['financial-times', 'financial-times'],
  ['hm-agency', 'hm-agency'],
  ['inseec.com', 'inseec.com'],
  ['jaiunpotedanslacom', 'jaiunpotedanslacom'],
  ['kelformation', 'kelformation'],
  ['kwanko', 'kwanko'],
  ['letudiant', 'letudiant'],
  ['lepetitjournal', 'lepetitjournal'],
  ['major-prepa', 'major-prepa'],
  ['manageo', 'manageo'],
  ['mastertube', 'mastertube'],
  ['naturel', 'naturel'],
  ['nomad-education', 'nomad-education'],
  ['onisep', 'onisep'],
  ['pixalione', 'pixalione'],
  ['real-estate', 'real-estate'],
  ['sls-data', 'sls-data'],
  ['sport', 'sport'],
  ['studizzbot', 'studizzbot'],
  ['studyadvisor', 'studyadvisor'],
  ['studyportal', 'studyportal'],
  ['studyrama', 'studyrama'],
  ['supdecrea', 'supdecrea'],
  ['team-brain', 'team-brain'],
  ['the-economist', 'the-economist'],
  ['timeone', 'timeone'],
  ['twitch', 'twitch'],
  ['wine-spirits', 'wine-spirits'],
  ['display-sites-editeurs', 'display-sites-editeurs'],
  ['display-autres', 'display-autres'],
  ['dv360', 'dv360'],
  ['amazon', 'amazon'],
  ['vl-media', 'vl-media'],
  ['aquitec', 'aquitec'],
  ['hopscotch', 'hopscotch'],
  ['together', 'together'],
  ['carrefours-emploi', 'carrefours-emploi'],
  ['monsieur-ecole-commerce', 'monsieur-ecole-commerce'],
  ['academie-toulouse', 'academie-toulouse'],
  ['azimut', 'azimut'],
  ['aef-info', 'aef-info'],
  ['salon-autre', 'salon-autre'],
])
