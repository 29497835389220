import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  selectedCategory: undefined as string | undefined,
  list: undefined as HTMLUListElement | undefined,
  busy: false, // To disable form fields while animating
  init() {
    const list = this.$root.querySelector<HTMLUListElement>(
      '[x-bind="carouselContainer"]',
    )
    if (!list) throw new Error('List not found')

    this.list = list

    this.$watch('selectedCategory', (_value, oldValue) => {
      if (oldValue === undefined) return
      this.onSelectedCategoryChange(true)
    })
  },
  async onSelectedCategoryChange(animate: boolean = true) {
    if (!this.list) return

    // Find selected radio button
    const radio = this.$root.querySelector<HTMLInputElement>(
      `input[name="category"][value="${this.selectedCategory}"]`,
    )

    // Retrieve URL from radio button
    if (!radio) return
    const { url } = radio.dataset
    if (!url) return

    if (animate) {
      this.busy = true
      await this.animateList(this.list, 'leave').then()
    }

    // Fetch new content
    const response = await fetch(url)
    const html = await response.text()

    // Update list
    this.list.innerHTML = html

    if (animate) {
      await this.animateList(this.list, 'enter').then()
      this.busy = false
    }
  },
  animateList(list: HTMLUListElement, action: 'enter' | 'leave') {
    const isEnter = action === 'enter'

    const stagger = {
      each: isEnter ? 0.07 : undefined,
      onStart(this: gsap.core.Tween) {
        const target = this.targets<HTMLElement>()[0]
        target.style.transition = 'none'
      },
      onComplete(this: gsap.core.Tween) {
        const target = this.targets<HTMLElement>()[0]
        target.style.transition = ''
      },
    }

    const elements = list.querySelectorAll('article')

    // Enter animation
    if (isEnter) {
      return gsap.fromTo(
        elements,
        { opacity: 0, x: 120, scale: 1.1 },
        {
          opacity: 1,
          x: 0,
          scale: 1,
          clearProps: 'all',
          duration: 0.7,
          ease: 'back.out(1.7)',
          stagger,
        },
      )
    }

    // Leave animation
    return gsap.to(elements, {
      opacity: 0,
      x: -50,
      duration: 0.3,
      ease: 'power3.inOut',
      stagger,
    })
  },
}))
